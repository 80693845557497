const VISTAPRINT = 'vistaprint';

const CONTENTFUL_SPACES: { [id: string]: any } = {
  '9yjwzxk07tr8': { tenant: VISTAPRINT, region: 'ANZS' },
  olowdmhx072v: { tenant: VISTAPRINT, region: 'EU' },
  p0rr4c9t7js3: { tenant: VISTAPRINT, region: 'NA' },
  vutkdqi6trjg: { tenant: VISTAPRINT, region: 'IN' },
  '1br7uk824q8a': { tenant: VISTAPRINT, region: 'DAVIDLOCAL' }
};

export const getTenant = (space: string) => {
  if (CONTENTFUL_SPACES[space]) {
    return CONTENTFUL_SPACES[space].tenant;
  }
  return null;
};
