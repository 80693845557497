import { SidebarAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import CustomPublishButton from '../components/CustomPublishButton';
import { SlugValidationService } from '../services/SlugValidationService';
import { getTenant } from '../providers/TenantProvider';
import { CollectionProp, ContentTypeFieldValidation, EntryProps, SnapshotProps } from 'contentful-management';
import { Note } from '@contentful/f36-components';
import UrlServiceClient from '../providers/UrlServiceClient';
import { ValidationResult } from '../types/ValidationResult';
import { DiscoveryPage, DiscoveryPageEntry } from '../types/DiscoveryPage';
import ExtendedInformation from '../components/ExtendedInformation';
import { useEffect, useState } from 'react';
import { FieldNames } from '../constants';

const Sidebar = () => {
  const sdk = useSDK<SidebarAppSDK>();
  const cma = sdk.cma;
  const tenant = getTenant(sdk.ids.space);
  const urlServiceClient = new UrlServiceClient();

  const [slugValidationPattern, setSlugValidationPattern] = useState<string>('');

  const isProductionEnv = () => {
    return sdk.entry.getSys()?.environment?.sys?.id === 'master';
  };

  const [discoveryPageData, setDiscoveryPageData] = useState<DiscoveryPage>({
    discoveryId: '',
    categoryId: '',
    categoryUrl: {},
    availability: {},
    contextVariants: {},
    slugs: {},
    discoveryPageIdFromSnapshot: '',
    isProduction: isProductionEnv()
  });

  useEffect(() => {
    const getDiscoveryPageIdFromSnapshot = async () => {
      if (isProductionEnv()) {
        const snapshot = (await cma.snapshot.getManyForEntry<DiscoveryPageEntry>({
          entryId: sdk.entry.getSys().id,
          snapshotId: ''
        })) as CollectionProp<SnapshotProps<EntryProps<DiscoveryPageEntry>>>;

        const isDraft = !snapshot || snapshot.items.length === 0;

        if (!isDraft) {
          const discoveryPageIdFromSnapshot = snapshot.items[0].snapshot.fields.discoveryId[sdk.locales.default];

          setDiscoveryPageData((prevDpData) => ({
            ...prevDpData,
            discoveryPageIdFromSnapshot: discoveryPageIdFromSnapshot
          }));
        }
      }
    };

    getDiscoveryPageIdFromSnapshot();
  }, []);

  useEffect(() => {
    const slug = sdk.entry.fields.slug;

    const slugValidationPattern: string =
      slug.validations.find((v: ContentTypeFieldValidation) => v.regexp)?.regexp?.pattern || '';

    setSlugValidationPattern(slugValidationPattern);

    sdk.entry.fields[FieldNames.DISCOVERY_ID].onValueChanged((value: any) => {
      setDiscoveryPageData((prevDpData) => ({
        ...prevDpData,
        discoveryId: value
      }));
    });

    sdk.entry.fields[FieldNames.MERCHANDISING_CATEGORY].onValueChanged(async (value: any) => {
      const categoryId = await getCategoryId();

      let transformedCategoryUrls: { [locale: string]: string } = {};

      if (categoryId) {
        const regions = sdk.entry.fields.pageAvailability.getValue().regions;

        for (const key in regions) {
          if (regions.hasOwnProperty(key)) {
            const categoryUrl = await urlServiceClient.getCategoryUrl(tenant, key, categoryId);
            transformedCategoryUrls[key] = categoryUrl;
          }
        }
      }

      setDiscoveryPageData((prevDpData) => ({
        ...prevDpData,
        categoryId: categoryId,
        categoryUrl: transformedCategoryUrls
      }));
    });

    sdk.entry.fields[FieldNames.PAGE_AVAILABILITY].onValueChanged(() => {
      const availability: any = sdk.entry.fields.pageAvailability.getValue();
      const regions: any = availability.regions;
      const contextVariants: any[] = availability.contextVariants;

      let transformedRegions: { [locale: string]: string } = {};
      let transformedVariants: { [locale: string]: string } = {};

      for (const key in regions) {
        if (regions.hasOwnProperty(key)) {
          transformedRegions[key] = regions[key].state;
        }
      }

      for (let i = 0; i < contextVariants.length; i++) {
        const variant = contextVariants[i];
        transformedVariants[variant.region] = variant.regionState.state;
      }

      setDiscoveryPageData((prevDpData) => ({
        ...prevDpData,
        availability: transformedRegions,
        contextVariants: transformedVariants
      }));
    });

    for (const localeKey in sdk.entry.fields[FieldNames.SLUG].locales) {
      const locale = sdk.entry.fields[FieldNames.SLUG].locales[localeKey];
      sdk.entry.fields[FieldNames.SLUG].getForLocale(locale).onValueChanged(() => {
        const transformedSlugs: { [locale: string]: string } = {};

        for (const locale in sdk.locales.available) {
          const code = sdk.locales.available[locale];
          const fallback = sdk.locales.fallbacks[code];
          transformedSlugs[code] = slug.getValue(code) || (fallback && slug.getValue(fallback));
        }

        setDiscoveryPageData((prevDpData) => ({
          ...prevDpData,
          slugs: transformedSlugs
        }));
      });
    }
  }, [sdk.entry, sdk.window]);

  const getCategoryId = async (): Promise<string> => {
    try {
      const defaultLocale = sdk.locales.default;

      const categoryLinkedEntry = sdk.entry.fields.merchandisingCategory.getValue();
      if (categoryLinkedEntry) {
        const categoryEntry = await sdk.cma.entry.get({ entryId: categoryLinkedEntry.sys.id });
        return categoryEntry.fields.mpvId[defaultLocale];
      }
    } catch (error) {
      console.error(error);
    }
    return '';
  };

  const validateDiscoveryPage = async (): Promise<ValidationResult> => {
    try {
      const validator = new SlugValidationService(tenant, slugValidationPattern, urlServiceClient);
      return await validator.validate(discoveryPageData);
    } catch (error) {
      console.error('Error validating slug: ', error);
      return {
        isValid: false,
        validationErrors: ['Unexpected error ocurred. Please contact us on Slack #help-discovery-pages']
      };
    }
  };

  if (!tenant) {
    return (
      <Note variant="negative">
        Invalid tenant. Please reach out to <strong>#help-discovery-pages</strong>
      </Note>
    );
  }

  return (
    <>
      <CustomPublishButton onValidate={validateDiscoveryPage}></CustomPublishButton>
      <ExtendedInformation tenant={tenant} discoveryPageData={discoveryPageData} />
    </>
  );
};

export default Sidebar;
